<template>
  <div>
    <el-row>
      <el-col :span="12">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item to="/published-list"
            >已发行作品列表</el-breadcrumb-item
          >
          <el-breadcrumb-item>作品详情</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="12" style="text-align: right"
        ><el-link type="primary" @click="$router.back()">
          &lt;返回</el-link
        ></el-col
      >
    </el-row>

    <el-divider></el-divider>

    <div class="base-info">
      <el-image :src="navdata.cover" class="cover"></el-image>
      <div>
        <div class="song-name">{{ navdata.name }}</div>
        <div class="info">
          <span class="label">平台</span>{{ issueterrace }}
        </div>
        <div class="info">
          <span class="label">发行时间</span>{{ issueTime }}
        </div>
      </div>
    </div>
        <el-row style="text-align:right; margin-bottom:10px;">
            <el-col>
                  <el-radio-group v-model="radio2" size="medium">
            <el-radio-button label="1">周</el-radio-button>
             <el-radio-button label="2" >月</el-radio-button>
    </el-radio-group>
            </el-col>
        </el-row>
    <div class="table-info">
      <el-table
        class="simple-table simple-table-small"
        :data="[navdata]"
        style="width: 100%"
      >
        <el-table-column align="center" prop="" label="趋势图" width="100">
          <template slot-scope="scope">
            <el-popover
                width="700"
                @show="showTrend(scope)"
                @hide="hideTrend(scope)"
                trigger="click">
              <trend-chart
                  :track-id="navdata.tmeData.tmeId"
                  :cp-company-id="navdata.tmeData.tmeCompanyId"
                  :cycle="radio2 === '1' ? 'week' : 'mon'"
                  v-if="isShowChart[0]"
              ></trend-chart>
              <el-button slot="reference" type="text">查看</el-button>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="tmeData.computeIntervalWeek"
          label="数据周期"
          width="100"
        >
        </el-table-column>
        <el-table-column width="200" v-slot="{ row }" label="歌曲信息">
          <div class="track-info">
            <el-image
              style="width:50px; height:50px; margin-right: 10px"
              class="cover"
              :src="row.cover || '/assets/defaultCover.png'"
            ></el-image>
            <div class="track-info-message">
              <div>
                   <span class="text">{{ row.name }}</span>
              </div>
              <div>
                {{ row.tmeData.singerName }}
              </div>
            </div>
          </div>
        </el-table-column>
          <el-table-column
        align="center"
        filter-change="identity"
        label="发行身份"
        prop="role"
        v-slot="{ row }"
        width="100"
      >
        <div style="text-align: center">{{ row.role.join('/') }}</div>
      </el-table-column>
        <el-table-column
          align="center"
          prop="tmeData.trackVersion"
          label="歌曲版本"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="tmeData.trackLanguage"
          label="语言"
        >
        </el-table-column>
        <el-table-column
          align="center"
          :show-overflow-tooltip="true"
          prop="tmeData.publicTime"
          label="发行时间"
        >
        </el-table-column>
        <el-table-column
          v-if="radio2==1"
          align="center"
          width="90"
          prop="tmeData.playCntWeek"
          label="播放指数/周"
        >
        </el-table-column>
        <el-table-column
        v-else
          align="center"
          width="90"
          prop="tmeData.playCnt"
          label="播放指数/月"
        >
        </el-table-column>
        <el-table-column
         v-if="radio2==1"
          align="center"
          width="90"
          prop="tmeData.downloadCntWeek"
          label="下载指数/周"
        >
        </el-table-column>
        <el-table-column
           v-else
          align="center"
          width="90"
          prop="tmeData.downloadCnt"
          label="下载指数/月"
        >
        </el-table-column>
         <el-table-column
         v-if="radio2==1"
          align="center"
          width="90"
          prop="tmeData.favCntWeek"
          label="收藏指数/周"
        >
        </el-table-column>
        <el-table-column
         v-else
          align="center"
          width="90"
          prop="tmeData.favCnt"
          label="收藏指数/月"
        >
        </el-table-column>
      </el-table>
    </div>
    <ul class="barnav">
      <li>
        <div class="title">
          <p>发行天数(天)</p>
          <p>{{ data.songIssue.releaseDay }}</p>
        </div>
        <div class="image">
          <img src="../assets/time.png" alt="" />
        </div>
      </li>
      <li>
        <div class="title">
          <p>累计收益(元)</p>
          <p>{{$toFindexFn(data.songIssue.income)}}</p>
        </div>
        <div class="image">
          <img src="../assets/profit.png" alt="" />
        </div>
      </li>
    </ul>
    <!-- 折线图 -->
    <!-- <div class="chart" ref="chart"></div> -->
    <!-- 发行记录 -->
    <!-- <div class="timeline">
      <p class="title">发行记录</p>
      <el-timeline>
        <el-timeline-item
          color="#FF004D"
          reverse="true"
          v-for="(activity, index) in activities"
          :key="index"
          :timestamp="activity.content"
        >
          {{ activity.timestamp }}
        </el-timeline-item>
      </el-timeline>
    </div> -->
     <!-- 我的权益 -->
    <!-- <div class="mylegal timeline">
      <p class="title">我的权益</p>
      <ul>
        <li>
          <div class="title">演 唱</div>
          <div class="center">一次买断+分成10%</div>
          <div class="details">详情</div>
        </li>
        <li>
          <div class="title">演 唱</div>
          <div class="center">一次买断+分成10%</div>
          <div class="details">详情</div>
        </li>
        <li>
          <div class="title">演 唱</div>
          <div class="center">一次买断+分成10%</div>
          <div class="details">详情</div>
        </li>
      </ul>
    </div> -->
     <!-- 入账明细 -->
    <!-- <div class="timeline">
      <p class="title">入账明细</p>
      <ul
        class="barnav"
        style="justify-content: space-between; marginright: 0px"
      >
        <li>
          <div class="title">
            <p>核销成本(元)</p>
            <p>296</p>
          </div>
          <div class="image">
            <img src="../assets/cost.png" alt="" />
          </div>
        </li>
        <li>
          <div class="title">
            <p>核销预付(元)</p>
            <p>2934566</p>
          </div>
          <div class="image">
            <img src="../assets/advance.png" alt="" />
          </div>
        </li>
        <li>
          <div class="title">
            <p>分成收益(元)</p>
            <p>2934566</p>
          </div>
          <div class="image">
            <img src="../assets/nings.png" alt="" />
          </div>
        </li>
      </ul>
    </div> -->
    <!-- 入账明细表格 -->
    <!-- <div class="tab">
      <el-table
        :data="tableData"
        border
        :header-cell-style="headClass"
        style="width: 100%"
      >
        <el-table-column prop="date" label="渠道"> </el-table-column>
        <el-table-column prop="name" label="时间"> </el-table-column>
        <el-table-column prop="address" label="核销成本(元)"> </el-table-column>
        <el-table-column prop="address" label="核销预付(元)"> </el-table-column>
        <el-table-column prop="address" label="分成收入(元)"> </el-table-column>
      </el-table>
    </div> -->
    <div class="but">
      <button @click="$router.back()">返回</button>
    </div>
  </div>
</template>
<script>
import api from '../api'
import * as echarts from 'echarts'
import TrendChart from "../components/TrendChart"
export default {
  name: 'PublishedView',
  components: {
    TrendChart,
  },
  data () {
    return {
        //月周
       radio2:'1',

      isShowChart: [false],
      // 发行时间
      issueTime: '',
      issueterrace: '',
      navdata: [],
      data: {
        songIssue: {
          releaseDay: '',
          income: '',
          release: []
        }
      },
      activities: [
        {
          content: '活动按期开始',
          timestamp: '2018-04-15'
        },
        {
          content: '通过审核',
          timestamp: '2018-04-13'
        },
        {
          content: '创建成功',
          timestamp: '2018-04-11'
        }
      ],
      tableData: [
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄'
        },
      ]
    }
  },
  created () {
    if (this.$route.params.data) {
      localStorage.setItem('data', JSON.stringify(this.$route.params.data))
    }

    this.navdata = JSON.parse(localStorage.getItem('data'))
  },
  mounted () {
    this.getSong({ songId: this.navdata.id })
    //折线图
    // this.curve()
  },
  methods: {
    getSong (p) {
      api.getPublishedView(p).then((r) => {
        this.issueTime = r.songIssue.release[0].onlineTime
        this.issueterrace=r.songIssue.release[0].issuePlatFormValue
        this.data = r || this.data
        // 发行身份重新取值
        this.navdata.role=r.songRight[0].role
      })
    },
    headClass () {
      return 'background:#EDF1F4;'
    },
    // 曲线图展示
    curve () {
      var myChart = echarts.init(this.$refs.chart)
      var option
      option = {
        grid: {
          x2: 35,
          y2: 52,
          x: 65,
          y: 80
        },
        tooltip: {
          show: true, // 是否显示
          extraCssText: 'width:120px;height:60px;',
          trigger: 'axis',
          formatter (params) {
            return `<div  style="margin-left:12px" class="big">
                  <p style="margin:0px; margin-top:7px;font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #535875;">${params[0].name}</p>
                  <p style="margin:0px;
                         margin-top:10px;
                        font-size: 14px;
                        font-family: PingFangSC-Semibold, PingFang SC;
                        font-weight: 600;
                        color: #FF004D;
                  ">￥${params[0].value}</p>
              </div>`
          }
        },
        title: {
          text: '歌曲收益',
          padding: [24, 0, 0, 20],
          TextStyle: {
            fontFamily: 'PingFangSC-Semibold, PingFang SC',
            color: '#333333',
            fontWeight: 600
          }
        },
        xAxis: {
          axisLine: {
            show: false
          },
          axisLabel: {
            textStyle: {
              fontSize: 13,
              fontFamily: 'HelveticaNeue',
              color: '#AAAAAA'
            }
          },
          type: 'category',
          boundaryGap: false,
          axisTick: {
            show: false
          },
          offset: 15,
          minInterval: 3,
          data: [
            '1月',
            '2月',
            '3月',
            '4月',
            '5月',
            '6月',
            '7月',
            '8月',
            '9月',
            '10月',
            '11月',
            '12月'
          ]
        },
        yAxis: {
          offset: 13,
          type: 'value',
          axisLabel: {
            textStyle: {
              fontSize: 13,
              fontFamily: 'HelveticaNeue',
              color: '#AAAAAA'
            }
          }
        },
        series: [
          {
            data: [
              150,
              2300,
              224,
              2718,
              1935,
              147,
              2603,
              150,
              2300,
              224,
              2718,
              1935,
              147,
              2603
            ],
            type: 'line',
            areaStyle: {
              opacity: 0.1
            },
            itemStyle: {
              normal: {
                color: '#FF004D',
                lineStyle: {
                  color: '#FF004D'
                }
              }
            }
          }
        ]
      }
      option && myChart.setOption(option)
    },
    showTrend(value){
      this.isShowChart.splice(value.$index, 1, true)
    },
    hideTrend(value){
      this.isShowChart.splice(value.$index, 1, false)
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/initialization.scss";
@import "~@/styles/simple-table.scss";
.el-divider{
  background-color: #f5f5f5;
}
.track-info {
  display: flex;
}
.track-info-message {
  display: flex;
  width: 128px;
  flex-direction: column;
  justify-content: space-between;
  .text{
      display: inline-block;
      width: 60px;
      overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
  }
}
.big {
  p:nth-child(1) {
    margin-top: 0px;
    color: red;
  }
  p:nth-child(2) {
    margin-top: 0px;
  }
}
.el-divider {
  margin-top: 12px;
  margin-bottom: 40px;
}
.base-info {
  display: flex;
  .cover {
    width: 140px;
    height: 140px;
    margin-right: 20px;
    margin-bottom: 30px;
  }
  .song-name {
    font-size: 20px;
    font-weight: 600;
    color: #333333;
    margin-bottom: 13px;
  }
  .info {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 32px;
    .label {
      display: inline-block;
      width: 56px;
      color: #999999;
      text-align-last: justify;
      margin-right: 18px;
    }
  }
}
.table-info {
  border: #e7e8f2 1px solid;
  width: 100%;
  height: 134px;
  position: relative;
}

.barnav {
  margin-top: 40px;
  display: flex;
  li {
    margin-right: 15px;
    width: 270px;
    height: 100px;
    background: #ffffff;
    border: 1px solid #e7e8f2;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 24px;
    .title {
      p:nth-child(1) {
        margin-top: 16px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
      }
      p:nth-child(2) {
        margin-top: 8px;
        height: 45px;
        font-size: 32px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #333333;
        line-height: 45px;
        letter-spacing: 1px;
      }
    }
    .image {
      width: 100px;
      height: 100px;
      img {
        width: 100%;
      }
    }
  }
}
.chart {
  margin-top: 40px;
  width: 840px;
  height: 343px;
  background: #ffffff;
  border: 1px solid #e7e8f2;
}
.timeline {
  margin-top: 40px;
  > .title {
    height: 28px;
    font-size: 20px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 28px;
    margin-bottom: 20px;
  }

  ::v-deep .el-timeline-item__wrapper {
    display: flex;
    align-items: center;
    padding-bottom: 20px;
    .el-timeline-item__content {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
    }
    .el-timeline-item__timestamp {
      margin-top: 0px;
      margin-left: 24px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
    }
  }
  ::v-deep .el-timeline-item__tail {
    border-left: 2px solid #ff004d;
  }
}
.mylegal {
  ul {
    li {
      display: flex;
      margin-bottom: 12px;
      .title {
        width: 56px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        text-align: justify;
        text-align-last: justify;
      }
      .center {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        margin-left: 18px;
        margin-right: 10px;
      }

      .details {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff004d;
        line-height: 20px;
      }
    }
  }
}
.tab {
  margin-top: 31px;
}
.but {
  text-align: center;
  margin-top: 64px;
  button {
    border: none;
    width: 202px;
    height: 50px;
    background: #ff004d;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 14px;
  }
}
</style>
